import { createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65642b6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "wrapper",
  ref: "modalRef"
}
const _hoisted_2 = { class: "main-form" }
const _hoisted_3 = { class: "main-form" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = {
  key: 0,
  class: "cost-and-lead-time-info"
}
const _hoisted_6 = { class: "add-surface-finish-modal-actions costing-parameters-action-modal-actions main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "add-surface-finish-modal costing-parameters-action-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Add Quality control"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["NForm"], {
          class: "add-surface-finish-form costing-parameters-action-form",
          ref: "formRef",
          model: $setup.form
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode($setup["NFormItem"], {
                class: "name",
                required: "",
                path: "processName",
                label: "Process name",
                rule: $setup.processNameValidation()
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["NInput"], {
                    class: "tabbable-focusable",
                    value: $setup.form.processName,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.processName) = $event)),
                    placeholder: "Enter",
                    onKeydown: _withKeys($setup.onKeydownTab, ["tab"]),
                    onBlur: _cache[1] || (_cache[1] = ($event: any) => ($setup.form.processName = $setup.onCleanExtraSpaces($setup.form.processName)))
                  }, null, 8, ["value", "onKeydown"])
                ]),
                _: 1
              }, 8, ["rule"])
            ])
          ]),
          _: 1
        }, 8, ["model"]),
        _createVNode($setup["NForm"], {
          class: "mfr-regions-form costing-parameters-action-form",
          ref: "formMfrRegionsRef",
          model: $setup.formMfrRegions
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.formMfrRegions, (value, key) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(key) + " manufacturing region", 1),
                _createVNode($setup["NFormItem"], {
                  path: `${key}.available`,
                  label: "Availability"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["SelectableTableCell"], {
                      value: $setup.formMfrRegions[key].available ? 'Yes' : 'No',
                      options: $props.availabilityOptions,
                      onUpdateValue: (data) => $setup.formMfrRegions[key].available = data === $setup.AvailabilityValueEnum.Yes,
                      selectInForm: "",
                      onKeydownTab: $setup.onKeydownTabHandler
                    }, null, 8, ["value", "options", "onUpdateValue", "onKeydownTab"])
                  ]),
                  _: 2
                }, 1032, ["path"]),
                (value.available)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode($setup["NFormItem"], {
                        path: `${key}.costPerHour`,
                        required: "",
                        label: `Cost per Hour (${$setup.formMfrRegions[key].mfrRegionCurrencySign})`,
                        rule: $setup.baseCostNumberFieldRules(`${key} cost`)
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["NInput"], {
                            class: "tabbable-focusable",
                            value: $setup.formMfrRegions[key].costPerHour,
                            "onUpdate:value": ($event: any) => (($setup.formMfrRegions[key].costPerHour) = $event),
                            placeholder: "Enter",
                            onBlur: ($event: any) => ($setup.formMfrRegions[key].costPerHour = $setup.onCleanExtraSpaces($setup.formMfrRegions[key].costPerHour)),
                            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
                          }, {
                            prefix: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.formMfrRegions[key].mfrRegionCurrencySign), 1)
                            ]),
                            _: 2
                          }, 1032, ["value", "onUpdate:value", "onBlur", "onKeydown"])
                        ]),
                        _: 2
                      }, 1032, ["path", "label", "rule"]),
                      _createVNode($setup["NFormItem"], {
                        path: `${key}.dimLeadTime`,
                        required: "",
                        label: "Dim. Lead Time (min)",
                        rule: $setup.baseCostNumberFieldRules(`${key} Dim. Lead time`)
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["NInput"], {
                            class: "tabbable-focusable",
                            value: $setup.formMfrRegions[key].dimLeadTime,
                            "onUpdate:value": ($event: any) => (($setup.formMfrRegions[key].dimLeadTime) = $event),
                            placeholder: "Enter",
                            onBlur: ($event: any) => ($setup.formMfrRegions[key].dimLeadTime = $setup.onCleanExtraSpaces($setup.formMfrRegions[key].dimLeadTime)),
                            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
                          }, null, 8, ["value", "onUpdate:value", "onBlur", "onKeydown"])
                        ]),
                        _: 2
                      }, 1032, ["path", "rule"]),
                      _createVNode($setup["NFormItem"], {
                        path: `${key}.ctqDimLeadTime`,
                        required: "",
                        label: "QCT Lead Time (min)",
                        rule: $setup.baseCostNumberFieldRules(`${key} QCT lead time`)
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["NInput"], {
                            class: "tabbable-focusable",
                            value: $setup.formMfrRegions[key].ctqDimLeadTime,
                            "onUpdate:value": ($event: any) => (($setup.formMfrRegions[key].ctqDimLeadTime) = $event),
                            placeholder: "Enter",
                            onBlur: ($event: any) => ($setup.formMfrRegions[key].ctqDimLeadTime = $setup.onCleanExtraSpaces($setup.formMfrRegions[key].ctqDimLeadTime)),
                            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
                          }, null, 8, ["value", "onUpdate:value", "onBlur", "onKeydown"])
                        ]),
                        _: 2
                      }, 1032, ["path", "rule"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 256))
          ]),
          _: 1
        }, 8, ["model"]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode($setup["NButton"], {
            class: "update-button n-button-save medium-button tabbable-focusable",
            type: "info",
            onClick: $setup.onAddQualityControl,
            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
          }, {
            default: _withCtx(() => [
              _createTextVNode("Add")
            ]),
            _: 1
          }, 8, ["onKeydown"]),
          _createVNode($setup["NButton"], {
            class: "medium-button n-button-cancel tabbable-focusable",
            type: "tertiary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onKeydown"])
        ])
      ], 512)
    ]),
    _: 1
  }))
}